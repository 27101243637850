<template>
  <shipblu-card class="mb-6 mt-2 p-5" :cardLoading="passwordLoading">
    <form :v-model="data">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input ref="password" type="password" v-validate="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }" :label-placeholder="$t('Password')" name="password" v-model="data.password" class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input type="password" v-validate="{ required: true, confirmed:'password' ,regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }" :label-placeholder="$t('Confirm Password')" name="confirm password" v-model="data.confirm_password" class="w-full" data-vv-as="password" />
          <span class="text-danger text-sm" v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
        </div>
      </div>
    </form>
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end mt-6">
      <vs-button class="ml-auto mt-2" @click="changePassword">{{$t('Save Changes')}}</vs-button>
      <vs-button class="ml-4 mt-2" type="border" color="warning">{{$t('Reset')}}</vs-button>
    </div>
  </shipblu-card>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
import common  from '@/assets/utils/common'

export default {
  data () {
    return {
      userRolesDic: {
        'merchant': 'merchants',
        'finance': 'finances',
        'admin': 'admins',
        'manager': 'managers',
        'customer-support': 'customers-support',
        'head-of-fleet': 'heads-of-fleet',
        'transporter': 'transporters',
        'growth-hacker': 'growth-hackers',
        'fc-operations-supervisor': 'fc-operations-supervisor',
        'fc-receiving-agent':'fc-receiving-agent',
        'fc-inbounding-agent':'fc-inbounding-agent',
        'fc-storing-agent': 'fc-storing-agent',
        'fc-packing-agent': 'fc-packing-agent',
        'fc-picking-agent': 'fc-picking-agent',
        'sales': 'sales',
        'ops-admin': 'ops-admins'
      },
      data: {
        password: '',
        confirm_password: ''
      }, 
      passwordLoading: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    changePassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.passwordLoading = true
          sendRequest(true, false, this, `api/v1/users/${this.userRolesDic[this.$store.state.AppActiveUser.userRole]}/${this.activeUserInfo.uid}/`, 'patch', this.data, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Password'] + i18nData[this.$i18n.locale][' has been changed successfully!'],
                position: 'top-center'
              })
              this.data = {
                password: '',
                confirm_password: ''
              }
              this.passwordLoading = false
            }, (error) => {
              this.passwordLoading = false
              common.notifyResponseError(this, error)
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluCard
  }
}
</script>
